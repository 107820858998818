<template>
  <div>
     <CRow>
 <CCol col="12" xl="12">
   <CCard>
     <CCardHeader class="">
     <CRow>
     <CCol col="6" xl="6">
        <h6 class="w-100">Domov  > Nastavenia >  Bloky</h6>
                 <h1 class="w-100" >Bloky</h1>
     </CCol>
     <CCol col="6" xl="6">
          <div class="text-right system-options w-100 d-block" style="">
            <router-link to="/block/add" class="nav-link"
                                                 href="/block/add">
                                        <template>
                                            <a-button type="secondary" >Pridať blok</a-button>
                                        </template>
                                    </router-link>
         </div>
     </CCol>
     </CRow>
     </CCardHeader>
     <CCardBody>
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :row-selection="rowSelection"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                     :pagination="pagination"
                                    :loading="loading"
                                    class="table-flush">
                                <template slot="contenttype" slot-scope="ct">
                                    <span v-if="ct">{{fetchcttitle(ct)}}</span>
                                    <span v-else>Všetky typy obsahov</span>
                                </template>

                                <template slot="name" slot-scope="user">
                                <span class="actions">

                                       <router-link :to="'/block/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/block/' + user.id">
                                        <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                   </span>
                                </template>
                            </a-table>
                        </div>
                          <div  style="bottom:0" class="action-panel w-25 m-3 float-left"  v-if="rowSelection.selectedRowKeys.length > 0">
                                <a-select style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si akciu" v-model="action" :options="actions"></a-select>
                                <a-popconfirm placement="topRight"
                                              @confirm="doAction"
                                              @cancel="cancel"
                                              okText="Áno"
                                              cancelText="Nie"
                                >
                                    <template slot="title">
                                        <h5 color="danger" >Prajete si vykonať tento príkaz?</h5>
                                        Príkaz sa vykoná pre {{rowSelection.selectedRowKeys.length}} <span v-if="rowSelection.selectedRowKeys.length === 1">blok</span> <span v-if="rowSelection.selectedRowKeys.length > 1 && rowSelection.selectedRowKeys.length < 5">bloky</span> <span v-if="rowSelection.selectedRowKeys.length > 4">blokov</span>.
                                    </template>
                                   <a-button type="warning">Vykonať</a-button>
                                </a-popconfirm>
                            </div>
                            </CCardBody>
                            </CCard>
                            </CCol>
                            </CRow>

                    </div>

</template>
<script>
    import {dataService} from "../_services";
    import {Popconfirm, Select} from "ant-design-vue"


    export default {
        name: "blocks",
        props: ["id"],
        components: {
            "a-popconfirm": Popconfirm,
            "a-select": Select
        },
        data() {
            return {
                data: [],
                ctt: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                languages: null,
                filteredInfo: null,
                actions: [{"value" : 'delete' , "label" : 'Zmazať'}],
                action:undefined,
                pagination: {
                    hideOnSinglePage: true,
                    pageSize: 100, // default number of pages per page
                    showSizeChanger: true, // display can change the number of pages per page
                    pageSizeOptions: ['10', '20', '30', '40', '100'], // number of pages per option
                    showTotal: total => `Položiek: ${total}`, // show total
                    showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
                },
                sortedInfo: null,
                selectedRowKeys: [],
            };
        },
        computed: {
            columns() {
                // eslint-disable-next-line no-unused-vars
                let { sortedInfo, filteredInfo } = this;
                sortedInfo = sortedInfo || {};
                filteredInfo = filteredInfo || {};
                const columns = [
                    {
                        title: 'Popis bloku',
                        dataIndex: 'des',
                        sorter: true,
                        width: '20%',
                    },
                   {
                        title: 'Jazyk',
                        scopedSlots: {customRender: 'language'},
                        filters: this.languages,
                        onFilter: (value, record) => record.language.includes(value),
                        dataIndex: 'language',
                    },
                    {
                        title: 'Typ obsahu',
                        scopedSlots: {customRender: 'contenttype'},
                        filters: this.ctt,
                        onFilter: (value, record) => record.content_type.includes(value),
                        dataIndex: 'showon',
                    },

                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ];
                return columns;     },
                          rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: true,
                    onSelection: this.onSelection,
                };
            },
        },
        mounted() {
            this.fetch();
        },
        methods: {
              onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
               doAction()
                {
                    if(this.action)
                    {
                    dataService.axiosPost('blockaction/' + this.action, { 'blocks' : this.rowSelection.selectedRowKeys}).then(() => {
                        this.rowSelection.selectedRowKeys = [];
                        this.selectedRowKeys = [];
                        this.fetch();
                        this.action = undefined;

                    });
                   }
                },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            clearFilters() {
                this.filteredInfo = null;
            },
            fetchcttitle(ct)
            {
                let tcc = this.contenttypes.find(x => x.slug === ct);
                return tcc.title;
            },
            fetch() {
                this.loading = true;
                /*
                                dataService.axiosFetch("updaterooms").then(results => {
                                console.log(results);
                                    this.loading = false;
                                });
                */dataService.axiosFetch("contenttypes").then(results => {
                    this.contenttypes = results;

                    results.forEach(obj => {
                        var iid = obj.id;
                        var vval = obj.title;
                        this.ctt.push({"text" : vval,"value" : iid});


                    });
                });
                   dataService.axiosFetch("languages").then(results => {
                var roleops = [];
              var actions = [];
              actions.push({"value" : 'delete' , "label" : 'Zmazať'});
                results.forEach(obj => {
                     roleops.push({"value" : obj.short , "text" : obj.title});
                     actions.push({"value" : obj.short , "label" : 'Vytvoriť ' + obj.short + ' preklad'});
                     });
                this.languages = roleops;
                this.actions = actions;
            });

                dataService.axiosFetch(this.$options.name,this.id).then(results => {
                    this.data = results;
                    this.loading = false;
                    //this.data.sort((a,b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0));


                  //  this.data.sort((a, b) => parseInt(a.id).localeCompare(parseInt(b.id), undefined, { numeric: true, sensitivity: 'base' }));
                    this.data.sort(function(a, b){
                        return parseInt(a.id)-parseInt(b.id)
                    })
this.data = this.data.reverse();
                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
